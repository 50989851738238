import React from "react";
import loadable from "@loadable/component";
const ImageLoader = loadable(() => import("../common/ImageLoader"));
import { motion } from "framer-motion";
import { useIsMobile } from "../../hooks";
import { SUPPORTED_REGIONS } from "../../../shared/enums";

import twoSizeImg from "assets/images/landingAir/two-size/two-size-mila.png";
import twoSizeImgWEBP from "assets/images/landingAir/two-size/two-size-mila.png?as=webp";
import chair from "assets/images/landingAir/two-size/chair.png";
import bookShell from "assets/images/landingAir/two-size/book-shell.png";
/**
 * @returns {JSX.Element}
 * @constructor
 */

const TwoSize = ({ localization }) => {
  const { region } = localization;
  const regional = SUPPORTED_REGIONS[region] || SUPPORTED_REGIONS.US;

  return (
    <section className="two-size">
      <div className="two-size__card">
        <div className="two-size__body">
          <img
            className="two-size__img-left"
            src={chair}
            alt="chair"
            loading="lazy"
          />
          <img
            className="two-size__img-right"
            src={bookShell}
            alt="book shell"
            loading="lazy"
          />
          <div className="container">
            <div className="two-size__header">
              <motion.h2
                className="two-size__title"
                initial={{ opacity: 0, y: 20 }}
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0 }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 20 },
                }}
              >
                And she’s designed for every possible air breather.
                {/*
            <strong>Two</strong>
            <br />
            sizes. */}
              </motion.h2>
              <motion.h3
                className="two-size__sub-title"
                initial={{ opacity: 0, y: 20 }}
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0 }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 20 },
                }}
              >
                Two sizes.
              </motion.h3>
            </div>
            <div className="two-size__main-wrap">
              <div className="two-size__wrap">
                <ImageLoader
                  className="two-size__media img-relative"
                  placeholderAspectRatio={775 / 530}
                  image={twoSizeImg}
                  webp={twoSizeImgWEBP}
                  alt={"Two Size Air Purifier"}
                />
                {/* <DimentionsInfo /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="two-size__footer">
          <DimentionsInfo regional={regional} />
        </div>
      </div>
    </section>
  );
};

const DimentionsInfo = ({ regional }) => {
  const isMobile = useIsMobile(769);
  return (
    <>
      <motion.div
        className="two-size__banner two-size__banner-left"
        initial={{ opacity: 0 }}
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0 }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0 },
        }}
      >
        <h3 className="two-size__banner-title">Mila Air 3</h3>
        <p className="two-size__banner-text">
          {isMobile ? (
            <>
              {" "}
              <strong>
                For larger living rooms, open spaces and larger bedrooms.
              </strong>
              <span>
                Size: 11.8” x 11.8” x 15.5” <br />
                CADR: 447 m<sup>3</sup>/hr
              </span>
            </>
          ) : (
            <>
              <strong>
                Perfect for larger living rooms, open spaces and larger bedroom
                suites. Winner of <em>TIME Magazine’s</em> “Best 100
                Inventions.”
              </strong>
              <span>
                Optimal for{" "}
                {regional === SUPPORTED_REGIONS.CA ? (
                  <>
                    37 m<sup>2</sup>
                  </>
                ) : (
                  <>
                    400 ft<sup>2</sup>
                  </>
                )}{" "}
                spaces at 5 ACH, with max coverage up to{" "}
                {regional === SUPPORTED_REGIONS.CA ? (
                  <>
                    93 m<sup>2</sup>
                  </>
                ) : (
                  <>
                    1,000 ft<sup>2</sup>
                  </>
                )}{" "}
                at 2 ACh. Small footprint of 11.8” x 11.8” x 15.5”
              </span>
            </>
          )}
        </p>
      </motion.div>
      <motion.div
        className="two-size__banner two-size__banner-right"
        initial={{ opacity: 0 }}
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.02 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        <h3 className="two-size__banner-title">Mila Air Mini</h3>
        <p className="two-size__banner-text">
          {isMobile ? (
            <>
              {" "}
              <strong>For medium-sized bedrooms, kitchens, flex spaces.</strong>
              <span>
                Size: 9.8” x 9.8” x 13.2” <br />
                CADR: 357 m<sup>3</sup>/hr
              </span>
            </>
          ) : (
            <>
              {" "}
              <strong>
                For medium-sized bedrooms, kitchens, flex spaces. Recognized by{" "}
                <em>Esquire Magazine</em> as the “Best Gadget of 2024.”
              </strong>
              <span>
                Optimal for{" "}
                {regional === SUPPORTED_REGIONS.CA ? (
                  <>
                    30 m<sup>2</sup>
                  </>
                ) : (
                  <>
                    318 ft<sup>2</sup>
                  </>
                )}{" "}
                spaces at 5 ACH, with max coverage up to{" "}
                {regional === SUPPORTED_REGIONS.CA ? (
                  <>
                    66 m<sup>2</sup>
                  </>
                ) : (
                  <>
                    714 ft<sup>2</sup>
                  </>
                )}{" "}
                at 2 ACH. 337 m<sup>3</sup>/hr CADR. Even smaller footprint at
                9.8” x 9.8” x 13.2”.
              </span>
            </>
          )}
        </p>
      </motion.div>
    </>
  );
};
export default TwoSize;
